import { MessageDiv, StyledArticle } from '../styles';

import { Layout } from '../components/Layout';
import React from 'react';
import { StyledH2 } from '../styles/common';
import { SEO } from '../components/SEO';
export const Head = () => (
	<SEO 
		title='Pliki dla Policji'
		description='Pliki dla Policji.'
	/>);
const ZdalnaPomocPage = () => (
	<Layout>
		<StyledArticle>
			<StyledH2 as="h1" color="info">
				Pliki dla Policji
			</StyledH2>
			<MessageDiv>
				<ul>
					<li>
						<a href="https://api.mzk.bydgoszcz.pl/downloads/GenetecVideoPlayer.exe">
							Odtwarzacz filmów - tramwaj PESA
						</a>
					</li>
				</ul>
			</MessageDiv>
		</StyledArticle>
	</Layout>
);

export default ZdalnaPomocPage;
